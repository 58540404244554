import { RawAxiosRequestHeaders } from 'axios'
import { axiosInstance } from './config'
import { getAuthToken } from './utils'

export const postSignup = (data: rateauditor.api.user.ISignupData) => {
  return axiosInstance.post(`/auth/signup`, data)
}

export const postLogin = (data: rateauditor.api.user.ILoginData) => {
  const result = axiosInstance.post(`/auth/login`, data)
  return result
}

export const getCurrentUserInfo = () => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const userInfo = axiosInstance.get(`/user/self`, { headers: headers })
  return userInfo
}

export const getUserResendVerifyEmail = () => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const result = axiosInstance.get(`/auth/resend-verify-email`, { headers: headers })
  return result
}

export const postPasswordChange = (data: { oldPassword: string; newPassword: string }) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const result = axiosInstance.post(`/auth/change-password`, data, { headers: headers })
  return result
}

export const getUserVerifyEmail = (token: string) => {
  const result = axiosInstance.get(`/auth/verify-email`, { params: { token } })
  return result
}

export const postUserForgotPassword = (info: { email: string }) => {
  const result = axiosInstance.post(`/auth/forgot-password`, info)
  return result
}

export const postVerifyToken = (data: { token: string; action: string }) => {
  const result = axiosInstance.post(`/auth/verify-credential`, data)
  return result
}

export const postUserResetPassword = (data: { newPassword: string; token: string }) => {
  const result = axiosInstance.post(`/auth/reset-password`, data)
  return result
}

export const sendFeedback = (data: {name: string, email: string, message: string }) => {
  const result = axiosInstance.post(`/contact-us`, data)
  return result
}

export const getUserById = (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
    const result = axiosInstance.get(`/user/by-id/${id}`, { headers: headers })
    return result
}

export const uploadProfilePic = (data: FormData) => {
  const token = getAuthToken({ bearerPrefix: true })
  const headers = {
    Authorization: token,
    "Content-Type": "multipart/form-data",
  }
  const result = axiosInstance.post(`/user/uploadProfilePicture`, data, { headers: headers })
  return result
}

export const deleteProfilePic = () => {
  const token = getAuthToken({ bearerPrefix: true })
  const headers = {
    Authorization: token,
  }
  const result = axiosInstance.delete(`/user/deleteProfilePicture`, { headers: headers })
  return result
}

export const getProfilePicFromUser = (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const result = axiosInstance.get(`/user/profilePic/${id}`, { headers: headers })
  return result
}

export const getLinkedAuditor = () => {
    const headers: RawAxiosRequestHeaders = {}
    const token = getAuthToken({ bearerPrefix: true })
    token && (headers.authorization = token)
    const result = axiosInstance.get(`/user/linked-auditor`, { headers: headers })
    return result
}
