import styles from "../ProfileHeader/style.module.sass";
import React, { useEffect, useState } from "react";
import { Avatar, Card, Col, Row, Tag, Input, Button } from "antd";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../../../redux/userSlice";
// Note: assume `auditorApi` now exports `setAuditorBio`
// to call POST /set-auditor-bio/:id
import { auditor as auditorApi, rate as rateApi, user as UserApi } from "@rap/api";
import RateList from "../../../../auditor/components/RateList/index";

const { TextArea } = Input;

const Auditor = () => {
    const UserInfo = useSelector(selectUserInfo);

    const [Auditor_ID, setAuditor_ID] = useState<string>("testingtesting");

    // We'll initially set an empty biography until we fetch from the API
    const [biography, setBiography] = useState("");
    const [tempBiography, setTempBiography] = useState("");
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [rates, setRates] = useState<rateauditor.api.rate.IRateInfo[]>([]);

    // Effect A: Fetch and set the Auditor ID
    useEffect(() => {
        const fetchAuditor = async () => {
            try {
                const userResponse = await UserApi.getLinkedAuditor();
                const newAuditorID = userResponse.data.linked_auditor || "";
                setAuditor_ID(newAuditorID);
            } catch (error) {
                console.error("Error fetching linked auditor:", error);
            }
        };

        fetchAuditor();
    }, []);

    // Effect B: Whenever Auditor_ID changes, fetch rates *and* the biography
    useEffect(() => {
        const fetchRatesAndBio = async () => {
            try {
                // 1) Fetch rates
                const rateResponse = await rateApi.getAuditorRates(Auditor_ID);
                setRates(rateResponse.data.rates);

                // 2) Fetch auditor info (including biography)
                const auditorResponse = await auditorApi.getAuditorInfo(Auditor_ID);
                // Example response shape:
                // {
                //   rate: {
                //     ...,
                //     auditor: {
                //       biography: "..."
                //     }
                //   },
                //   status: "success"
                // }

                const fetchedBio =
                    auditorResponse.data?.auditor?.biography ?? "";

                // 3) Update biography-related state
                setBiography(fetchedBio);
                setTempBiography(fetchedBio);
            } catch (error) {
                console.error("Error fetching rates or auditor info:", error);
            }
        };

        if (Auditor_ID) {
            // Only fetch if we actually have an Auditor ID
            fetchRatesAndBio();
        }
    }, [Auditor_ID]);

    // Editing Handlers
    const handleEdit = () => {
        setTempBiography(biography);
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            console.log('here')
            // 1) Call your API to update the bio on the backend
            await auditorApi.setAuditorBio(Auditor_ID, tempBiography);

            // 2) Update local state to reflect the new biography
            setBiography(tempBiography);
            setIsEditing(false);
        } catch (error) {
            console.error("Error saving biography:", error);
            // Optionally handle the error, e.g. show a notification
        }
    };

    const handleCancel = () => {
        // Revert changes
        setTempBiography(biography);
        setIsEditing(false);
    };

    return (
        <>
            <Card className={styles.biographySection}>
                <h3>About Me</h3>
                {isEditing ? (
                    <div>
                        <TextArea
                            rows={2}
                            value={tempBiography}
                            onChange={(e) => setTempBiography(e.target.value)}
                        />
                        <div style={{ marginTop: "8px" }}>
                            <Button
                                type="primary"
                                onClick={handleSave}
                                style={{ marginRight: "8px" }}
                            >
                                Save
                            </Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <p>{biography}</p>
                        <Button onClick={handleEdit}>Edit</Button>
                    </div>
                )}
            </Card>

            {/* Show the rate list */}
            <RateList isLoading={false} rates={rates} isAuditor={true} />
        </>
    );
};

export default Auditor;
