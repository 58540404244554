import React, { useEffect, useState } from 'react'
import { Button, Modal, Upload } from 'antd'
import type { UploadFile, UploadProps } from 'antd'
import ImgCrop from 'antd-img-crop';
import { user, user as userApi } from '@rap/api'
import 'antd/es/slider/style';
import { retrieveUserPicFromUser } from '../../../../utils/retrieveUserPic';

interface IAccountChangePictureProps {
  userInfo: rateauditor.api.user.IUserInfo
}

const AccountChangePicture = (props: IAccountChangePictureProps) => {
  const {userInfo} = props

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wasCancelled, setWasCancelled] = useState<Boolean>(false);
  const [ hasPic, setHasPic ] = useState<Boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleModalDelete = async () => {
    await user.deleteProfilePic()
    window.location.reload();
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const onFileChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleSubmit = async (request: any) =>{
    if(wasCancelled){
      setWasCancelled(false)
      return false
    }else{
      const formData = new FormData();
      formData.append('image', request.file);
      await user.uploadProfilePic(formData)
      window.location.reload();
    }
  }

  useEffect(() => { 
    const getProfilePic = async () => {
      const tempPicUrl = await retrieveUserPicFromUser(userInfo!._id)
      if (tempPicUrl !== ""){
        setHasPic(true)
      }
    }
    getProfilePic();
  }, []);

  return (
    <>
    <ImgCrop
      cropShape='round'
      modalOk='Submit Profile Picture'
      onModalCancel={()=>{setWasCancelled(true)}}
    >
      <Upload
        action=""
        fileList={fileList}
        onChange={onFileChange}
        customRequest={handleSubmit}
        maxCount={1}
        showUploadList={false}
      >
        <Button type="primary">Change Profile Picture</Button>
      </Upload>
    </ImgCrop>
    {hasPic ? 
    <Button danger onClick={showModal}>Delete Profile Picture</Button>: <></>
    }
    <Modal
      title="Delete Profile Picture"
      open={isModalOpen}
      closable={false}
      footer={[
        <Button key="back" onClick={handleModalCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" danger onClick={handleModalDelete}>
          Delete
        </Button>
      ]}
    >
      <p>Are you sure you want to delete your profile picture? This action cannot be undone.</p>
    </Modal>
    </>
  );
}

export default AccountChangePicture
