import React, { useEffect, useState } from 'react'
import { auditor as auditorApi, rate as rateApi } from '@rap/api'
import SearchSimple from './components/SearchSimple'
import HomePopular from './components/HomePopular'
import HomeRate from './components/HomeRate'
import { useNavigate } from 'react-router-dom'
import {selectUserInfo} from "../../redux/userSlice";
import {useSelector} from "react-redux";
import { Helmet } from 'react-helmet-async';

interface rateData {
    average: number,
    communication: number,
    comment: string,
    commenter: string,
    created_at: string,
    updated_at: string,
    _id: string,
    knowledge: number,
    quality: number,
    team: number,
    value: number,
    author: {
        username: string,
        permission_group: string[],
    }
    auditor: {
        _id: string,
        first_name: string,
        last_name: string,
        firm_issuing_state: string,
        firm_issuing_country: string,
        firm_issuing_city: string,
        firm_name: string,
    }
}

const Home = () => {
    const [popularAuditors, setPopularAuditors] = useState([])
    const [recentRates, setRecentRates] = useState([])
    const queryParams = new URLSearchParams(window.location.search)
    const param1 = queryParams.get('page')
    const param2 = queryParams.get('token')
    const navigate = useNavigate()
    const userInfo = useSelector(selectUserInfo)
		const structuredData = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://www.rateauditor.org/",
      "dateModified": "2024-08-13T18:32:23+01:00",
      "priority": "0.6"
    }



    useEffect(() => {
        auditorApi.getPopularAuditors().then((res) => {
          setPopularAuditors(res.data.recent_popular)
        })
        rateApi.getRecentVerifiedRates().then((res) => {
          setRecentRates(res.data.recent_rates)
        })
        if (param1) {
            if (param1 === '1') {
                navigate('/reset-password/?token=' + param2)
            } else {
                navigate(param1)
            }
        }
    }, [])



  return (
    <>
      <Helmet>
		    <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>	
		  </Helmet>
      <div className="bg-white shadow-md mb-6 p-6">
        <h2 className="font-bold text-xl">RateAuditor.org</h2>
        <p>
        Welcome to RateAuditor.org, your go-to platform for authentic auditor reviews and ratings. Here, you can evaluate auditors, audit managers and other audit staff. 
        You can share your experiences with the auditing process, and even claim your profile as an auditor. Join our vibrant community, ask questions, explore discussion analytics, and make well-informed decisions with confidence.
        </p>
      </div>
      <SearchSimple />
      <div className="md:grid grid-cols-2 gap-6">
        <HomePopular popularAuditors={popularAuditors} />
        <HomeRate recentRates={recentRates} />
      </div>
    </>
  )
}

export default Home
