import React, { useCallback, useEffect, useState } from 'react'
import { Button, Menu, Dropdown, Avatar, Space } from 'antd'
import { Link } from 'react-router-dom'
import { CaretDownOutlined } from '@ant-design/icons'
import { getInitials, retrieveUserPicFromUser } from '../../../../../pages/utils/retrieveUserPic'

interface INavbarUserProps {
    isLogin: boolean
    userInfo?: rateauditor.api.user.IUserInfo | null
    onLogout: () => void
}

const NavbarUser = (props: INavbarUserProps) => {
    const { isLogin, userInfo, onLogout } = props
    
    const [ hasPic, setHasPic ] = useState<Boolean>(false);
    const [ picURL, setPicURL ] = useState<string>("")

    const handleLogout = useCallback(() => {
        onLogout()
    }, [onLogout])

    const userHomeLink = `/user/${userInfo?.username ?? ''}`
    const userMenu = (
        <Menu style={{ minWidth: '200px' }}>
            <Menu.Item key="user:username">
                <Link to={userHomeLink}>
                    <span className="font-semibold text-black">Welcome Back! {userInfo?.username ?? ''}</span>
                </Link>
            </Menu.Item>
            <Menu.Divider />

            <Menu.Item key="user:dashboard" className="leading-8">
                <Link to={userHomeLink}>Dashboard</Link>
            </Menu.Item>

            <Menu.Item key="user:setting" className="leading-8">
                <Link to="/account/setting">Account Setting</Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="user:logout" className="leading-8">
                <Button type="text" onClick={handleLogout} block danger>
                    Log out
                </Button>
            </Menu.Item>
        </Menu>
    )


    const getUserPic = async () => {
        if(!isLogin){
            return
        }
        const tempPicUrl = await retrieveUserPicFromUser(userInfo!._id)
        if (tempPicUrl !== ""){
            setPicURL(Object.values(tempPicUrl).join(''))
            setHasPic(true)
        }
    }

    useEffect(() => {
        try {
            const temp = getUserPic()
        } catch (err) {
            console.log("error")
        }
    }, [userInfo])

    if (isLogin && userInfo) {
        getUserPic()
        return (
            <div>
                <Dropdown overlay={userMenu} placement="bottomRight" trigger={['click']}>
                    <Space style={{ padding: '0 4px', cursor: 'pointer' }}>
                        { hasPic ? 
                        <Avatar src={<img src={picURL}/>} style={{ backgroundColor: 'green'}} size="large"/>
                        :
                        <Avatar style={{ backgroundColor: 'green', verticalAlign: 'middle', fontSize: '16px'}} size="large">
                            {getInitials(userInfo.basic_profile.first_name, userInfo.basic_profile.last_name)}
                        </Avatar>
                        }   
                        <CaretDownOutlined />
                    </Space>
                </Dropdown>
            </div>
        )
    } else {
        return (
            <div>
                <Space>
                    <Link to="/login">
                        <Button type="dashed">Log In</Button>
                    </Link>
                    <Link to="/signup">
                        <Button type="primary">Sign Up</Button>
                    </Link>
                </Space>
            </div>
        )
    }
}

export default NavbarUser
