import { getProfilePicFromAuditor } from '@rap/api/es/auditor'
import { getProfilePicFromUser } from '@rap/api/es/user'

export const retrieveUserPicFromUser = async (id: string): Promise<string> => {
    const res = await getProfilePicFromUser(id)
    const data = await res.data
    delete data.status
    return Object.values(data).join('')
}

export const retrieveUserPicFromAuditor = async (id: string): Promise<string> => {
    const res = await getProfilePicFromAuditor(id)
    const data = await res.data
    delete data.status
    return Object.values(data).join('')
}

export const getInitials = (firstname: string, lastname: string) => {
    const initials = `${firstname.charAt(0)}${lastname.charAt(0)}`
    return initials.toUpperCase()
}