import React, { useState } from 'react';
import { Tabs } from 'antd';
import CommentVerification from './comment_verification';
import AuditorVerification from './auditor_profile_claims';
import AuditorApproval from '../browse/components/AuditorTable/AddAduitor-AdminApproval';
// import AuditorApproval from 'apps/main-web/src/pages/browse/components/AuditorTable/AddAduitor-AdminApproval.tsx'

const { TabPane } = Tabs;

const Admin = () => {

    const [activeTab, setActiveTab] = useState(localStorage.getItem('tab') || "1")

    const onChange = (key: string) => {
        setActiveTab(key);
        localStorage.setItem('tab', key)
    };

    return (
        <Tabs defaultActiveKey={activeTab} onChange={onChange}>
            <TabPane tab="Comment Verification" key="1">
                <CommentVerification />
            </TabPane>
            <TabPane tab="Auditor Claims" key="2">
                <AuditorVerification />
            </TabPane>
            <TabPane tab="Auditor Approval" key="3">
                <AuditorApproval />
            </TabPane>
        </Tabs>
    );
};

export default Admin;
