import { RawAxiosRequestHeaders } from 'axios'
import { axiosInstance } from './config'
import { getAuthToken } from './utils'

export const getPopularAuditors = async () => {
  return axiosInstance.get(`/recent-popular`)
}

export const getAuditorList = async () => {
  return axiosInstance.get(`/auditors`)
}

export const getAuditorInfo = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const userInfo = axiosInstance.get(`/auditor/${id}`, { headers: headers })
  return userInfo
}

export const getPendingAuditorClaims = async () => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.get(`/pending-claims`, { headers: headers})
}

export const rejectAuditorClaim = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.post(`/reject-claim/${id}`,{}, { headers: headers })
}

export const verifyAuditorClaim = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.post(`/verify-claim/${id}`, {}, { headers: headers })
}

export const claimAuditorProfile = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.post(`/submit-claim/${id}`, {}, { headers: headers })
}

export const addAuditor = async (auditorInfo: any)=> {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.post(`/auditor`, auditorInfo, { headers: headers })
}

export const verifyAuditor = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.post(`/verify-auditor/${id}`, {}, { headers: headers })
}

export const rejectAuditor = async (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.delete(`/reject-auditor/${id}`, { headers: headers })
}

export const getVerifiedAuditors = async () => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.get(`/verified-auditors`, { headers: headers })
}


export const getPaginateVerifiedAuditors = async (skip: number,  limit: number, name: string, firmName: string, location: string[]) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const params = {
    skip,
    limit,
    country: location[0],
    state: location[1],
    name: name.trim() !== "" ? name : undefined,// Handle empty string
    firmName: firmName.trim() !== "" ? firmName : undefined, // Handle empty string
  };
  return axiosInstance.get(`/paginated-verified`, { params: params,headers: headers})
}

export const getPendingAuditors = async () => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  return axiosInstance.get(`/pending-auditors`, { headers: headers })
}

export const getProfilePicFromAuditor = (id: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const result = axiosInstance.get(`/auditor/profilePic/${id}`, { headers: headers })
  return result
}

export const toggleAuditorShowPic = (id: string, show: Boolean) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)
  const result = axiosInstance.post(`/auditor/toggle-pic/${id}`, {showPic: show}, { headers: headers })
  return result
}

export const setAuditorBio = async (id: string, bio: string) => {
  const headers: RawAxiosRequestHeaders = {}
  const token = getAuthToken({ bearerPrefix: true })
  token && (headers.authorization = token)

  return axiosInstance.post(
      `/set-auditor-bio/${id}`,
      { bio },           // request body
      { headers: headers }
  )
}
